import { EStatus, Nullable } from '../../../shared/types/global-types';

export enum ETradeSettingsType {
  AUTO_CHANGE_LEVERAGE = 'AUTO_CHANGE_LEVERAGE',
  MULTI_ASSETS_MODE = 'MULTI_ASSETS_MODE',
  USE_BNB_FOR_SPOT_COMMISSION = 'USE_BNB_FOR_SPOT_COMMISSION',
  USE_BNB_FOR_FUTURES_COMMISSION = 'USE_BNB_FOR_FUTURES_COMMISSION',
  HIDE_ACCOUNT_BALANCE = 'HIDE_ACCOUNT_BALANCE',
  EXCLUDED_SUB_ACCOUNTS = 'EXCLUDED_SUB_ACCOUNTS',
  BALANCE_PNL_RESET_TIME = 'BALANCE_PNL_RESET_TIME',
  CLOSE_ORDERS_IF_POSITION_ZERO_BYBIT = 'CLOSE_ORDERS_IF_POSITION_ZERO_BYBIT',
  CLOSE_ORDERS_IF_POSITION_ZERO_BINANCE = 'CLOSE_ORDERS_IF_POSITION_ZERO_BINANCE'
}

export type TradingSettings = {
  auto_change_leverage: boolean,
  multi_assets_mode: boolean,
  balance_pnl_reset_time: string,
  use_bnb_for_spot_commission: boolean,
  use_bnb_for_futures_commission: boolean,
  hide_account_balance: boolean,
  excluded_sub_accounts_close_positions: number[],
  excluded_sub_accounts_cancel_orders: number[],
  close_orders_if_position_zero: {
    'Binance': boolean
    'Bybit': boolean,
  }
}

export type CloseOrdersIfFositionZeroChangeParams = {
  close_orders_if_position_zero: {
    exchange_id: number,
    value: boolean
  }
};

export type PartialTradingSettings = Partial<TradingSettings>

export interface ITradeSettingsSlice {
  status: EStatus
  settings: Nullable<TradingSettings>
}
