import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { ITradeSettingsSlice, PartialTradingSettings } from './types';
import { fetchTradeSettings } from './thunks';

const initialState: ITradeSettingsSlice = {
  settings: {
    auto_change_leverage: false,
    multi_assets_mode: false,
    balance_pnl_reset_time: '',
    use_bnb_for_spot_commission: false,
    use_bnb_for_futures_commission: false,
    hide_account_balance: true,
    excluded_sub_accounts_close_positions: [],
    excluded_sub_accounts_cancel_orders: [],
    close_orders_if_position_zero: {
      Binance: false,
      Bybit: false,
    },
  },
  status: EStatus.loading,
};

export const tradeSettingsSlice = createSlice({
  name: 'tradeSettings',
  initialState,
  reducers: {
    authChangeLeverage: (state, action: PayloadAction<boolean>) => {
      if (state.settings) {
        state.settings.auto_change_leverage = action.payload;
      }
    },
    multiAssetsMode: (state, action: PayloadAction<boolean>) => {
      if (state.settings) {
        state.settings.multi_assets_mode = action.payload;
      }
    },
    bnbForSpotCommission: (state, action: PayloadAction<boolean>) => {
      if (state.settings) {
        state.settings.use_bnb_for_spot_commission = action.payload;
      }
    },
    bnbForFuturesCommission: (state, action: PayloadAction<boolean>) => {
      if (state.settings) {
        state.settings.use_bnb_for_futures_commission = action.payload;
      }
    },
    hideAccountBalance: (state, action: PayloadAction<boolean>) => {
      if (state.settings) {
        state.settings.hide_account_balance = action.payload;
      }
    },
    clearTradeSettingsSlice: (state) => {
      state.settings = null;
    },
    excludedSubAccount: (state, action: PayloadAction<PartialTradingSettings>) => {
      if (state.settings) {
        state.settings.excluded_sub_accounts_cancel_orders = action.payload.excluded_sub_accounts_cancel_orders!;
        state.settings.excluded_sub_accounts_close_positions = action.payload.excluded_sub_accounts_close_positions!;
      }
    },
    setBalancePnlResetTime: (state, action: PayloadAction<string>) => {
      if (!state.settings) return;

      state.settings.balance_pnl_reset_time = action.payload;
    },
    setCloseOrdersIfPositionsZero: (state, action: PayloadAction<{ exchange: 'Binance' | 'Bybit', state: boolean }>) => {
      if (!state.settings?.close_orders_if_position_zero) return;

      if (action.payload.exchange === 'Binance') {
        state.settings.close_orders_if_position_zero.Binance = action.payload.state;
      }
      if (action.payload.exchange === 'Bybit') {
        state.settings.close_orders_if_position_zero.Bybit = action.payload.state;
      }
    },
    clearTradingSettingsSlice: (state) => {
      state.settings = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeSettings.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchTradeSettings.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.settings = action.payload;
      })
      .addCase(fetchTradeSettings.rejected, (state) => {
        state.status = EStatus.rejected;
      });
  },
});

export const {
  multiAssetsMode,
  excludedSubAccount,
  hideAccountBalance,
  authChangeLeverage,
  bnbForSpotCommission,
  bnbForFuturesCommission,
  clearTradeSettingsSlice,
  setBalancePnlResetTime,
  clearTradingSettingsSlice,
  setCloseOrdersIfPositionsZero,
} = tradeSettingsSlice.actions;
export const tradeSettingsReducer = tradeSettingsSlice.reducer;
