import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import Toogler from 'src/shared/ui/toogler/toogler';
import Button from 'src/shared/ui/button/button/button';
import styles from './bonus-my-bonuses.module.scss';

const BonusMyBonuses = () => {
  const { t } = useTranslation();
  const [autoConvert, setAutoConvert] = useState(false);
  const balanceVisible = useSelector(selectHiddenBalance);

  return (
    <section className={styles.container}>
      <div className={styles.title}>{t('my bonuses')}</div>
      <div className={styles.balance}>
        {t('current bonus balance')}
        <span>{!balanceVisible ? `${parseFloat('0').toLocaleString().replace(',', '.')} USDT` : HIDDEN_BALANCES}</span>
      </div>

      <Toogler isBlocked isActive={autoConvert} onChange={setAutoConvert} labelContent={t('auto convert')} />
      <p className={styles.info}>{t('conversion within a day after trading day salary')}</p>

      {autoConvert && (
        <div className={styles.manualConservation}>
          <p className={styles.manualConservationTitle}>{t('manual bonus conversion')}</p>

          <div className={styles.quantity}>
            <input type="number" placeholder={t('amount in USDT')} />
            <button>{t('all')}</button>
          </div>
          <Button disabled={false} loading={false} background="green">{t('convert')}</Button>

          <p className={styles.manualConservationHint}>{t('manual_conversion_hint')}</p>
        </div>
      )}
    </section>
  );
};
export default BonusMyBonuses;
